@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');


.mapboxgl-canvas {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mapboxgl-popup.unstyled-popup {
  .mapboxgl-popup-tip {
    display: none;
  }

  .mapboxgl-popup-content {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    pointer-events: none;
  }
}